import { createContext, useContext, useEffect, useState, useRef } from 'react';
import { usePostHog } from 'posthog-js/react';

import composeTrackUserActionWithPostHog from '../External/PostHog/trackUserActionWithPostHog';
import { getQueryParam } from '../Common/WebContext/queryParams';
import { User } from './User';

const AuthenticatedUserContext = createContext<User | null | undefined>(
  undefined
);

const useAuthenticatedUser = () => useContext(AuthenticatedUserContext);

type AuthenticatedUserProviderProps = {
  children: React.ReactNode;
  listenUser?: (onNext: (user: User | null) => void) => () => void;
};

const AuthenticatedUserProvider: React.FC<AuthenticatedUserProviderProps> = ({
  children,
  listenUser,
}) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);
  const cleanupRef = useRef<(() => void) | null>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const postHog = usePostHog();
  const trackUserAction = composeTrackUserActionWithPostHog(postHog);

  useEffect(() => {
    // fakes a user object on an internal build
    // NOTE: make sure .env.internal is sourced in the console before running 'npm run build:internal'
    if (import.meta.env.VITE_INTERNAL_BUILD_AUTH_CHECK === 'true') {
      const token = getQueryParam('token');
      if (token) {
        localStorage.setItem('token', token);
      }
      if (
        localStorage.getItem('token') ===
        import.meta.env.VITE_INTERNAL_ACCESS_TOKEN
      ) {
        if (user === undefined) {
          setUser({ email: 'internal@pipdecks.com' } as User);
        }
        return;
      }
    }

    // production build
    let retryCount = 0;
    const maxRetries = 3;

    const listeUserWithTimeout = () => {
      if (listenUser) {
        timeoutIdRef.current = setTimeout(() => {
          if (retryCount < maxRetries) {
            // console.log('listen user timed out, retrying');
            trackUserAction('retrying listen user');
            retryCount++;
            listeUserWithTimeout();
          } else {
            // TODO: throw error
          }
        }, 5000);

        if (cleanupRef.current) cleanupRef.current();
        cleanupRef.current = listenUser(newUser => {
          if (newUser !== undefined) {
            if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
          }
          setUser(newUser);
        });
      }
      return () => {
        if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current);
        if (cleanupRef.current) cleanupRef.current();
      };
    };

    return listeUserWithTimeout();
  }, [listenUser]);

  return (
    <AuthenticatedUserContext.Provider value={user}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export {
  AuthenticatedUserContext,
  AuthenticatedUserProvider,
  useAuthenticatedUser,
};
