import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import '../Common/SolveMyProblemFlow.css';

import { isDataLoadingResultSuccess } from '../../../Common/Types/DataLoadingResult';
import NotFoundPage from '../../../Common/Pages/NotFoundPage';
import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import AppContentContainer from '../../../Common/AppContentContainer';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import renderPageIfModelDefinedOrNotFoundPage from '../../../Common/Pages/pageIfModelDefinedOrNotFoundPage';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import { useAuthenticatedCustomer } from '../../Common/AuthorizedAccess/AuthenticatedCustomerContext';
import AuthorizedDeckContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';

import provideFocusBreadcrumbProps from '../Common/Breadcrumbs/provideFocusBreadcrumbProps';
import provideChallengeBreadcrumbProps from '../Common/Breadcrumbs/provideChallengeBreadcrumbProps';
import { SolveMyProblemRoutes } from '../Common/getSolveMyProblemRoutes';
import SolveMyProblemContent from '../Content/SolveMyProblemContent';
import SolveMyProblemListItem from '../Common/SolveMyProblemListItem/SolveMyProblemListItem';
import navigateToMissingSolutionForm, {
  MissingSolutionFormNavigationSource,
} from '../Common/MissingSolutionFeedback/navigateToMissingSolutionForm';
import composeTrackMissingSolutionActionWithPostHog, {
  TrackMissingSolutionActionPage,
} from '../Common/MissingSolutionFeedback/trackMissingSolutionAction';

type FocusListItem = {
  title: string;
  index: number;
  route: string;
};

const SolveMyProblemFocusPage: React.FC = () => {
  const { challengeIndex } = useParams<{ challengeIndex: string }>();
  const { t, ready: isTReady } = useTranslation('solve-my-problem');
  const router = useIonRouter();
  const customer = useAuthenticatedCustomer();
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);
  const trackMissingSolutionAction =
    composeTrackMissingSolutionActionWithPostHog(postHog);

  const challengeIndexNumber = Number(challengeIndex);
  const deckDetails = SolveMyProblemContent.deckOptions[challengeIndexNumber];
  if (!deckDetails) {
    return <NotFoundPage />;
  }

  const title = deckDetails.deckFocusPrompt;
  const challengeTitleSelected = deckDetails.deckLink;
  const [focuses, setFocuses] = useState<FocusListItem[] | null | undefined>();

  const loadAndDisplayData = (challengeIndex: string) => {
    const focuses: FocusListItem[] = deckDetails.focusOptions.map(
      (focus, focusIndex) => {
        return {
          title: focus.title,
          index: focusIndex,
          route: SolveMyProblemRoutes.tactics.resolve(
            challengeIndex,
            focusIndex.toString()
          ),
        };
      }
    );

    setFocuses(focuses);
  };

  const handleMissingSolutionClick = () => {
    trackMissingSolutionAction(
      TrackMissingSolutionActionPage.Focus,
      challengeTitleSelected
    );
    const customerEmail = isDataLoadingResultSuccess(customer)
      ? customer.data?.email
      : undefined;

    navigateToMissingSolutionForm(
      MissingSolutionFormNavigationSource.Focus,
      customerEmail,
      challengeTitleSelected
    );
  };

  const handleFocusSelection = (focus: FocusListItem) => {
    trackAction('select focus in Solve My Problem', {
      challengeTitle: challengeTitleSelected,
      focusTitle: focus.title,
    });
    router.push(focus.route);
  };

  const setDocumentTitle = () => {
    document.title = t('SolveMyProblemFocusPage_documentTitle');
  };

  useIonViewWillEnter(() => {
    setDocumentTitle();
  });

  useEffect(() => {
    if (!isTReady) {
      return;
    }

    loadAndDisplayData(challengeIndex);
  }, [challengeIndex, isTReady]);

  return renderPageIfModelDefinedOrNotFoundPage(
    focuses,
    isTReady ? (
      <IonPage
        id="solve-my-problem-focus"
        className="solve-my-problem-flow-container"
      >
        <AppContentContainer>
          <AuthorizedDeckContentAccessContainer deckId={deckDetails.deckId}>
            <Breadcrumbs>
              <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
              <TextBreadcrumb {...provideChallengeBreadcrumbProps(t, true)} />
              <TextBreadcrumb
                {...provideFocusBreadcrumbProps(t, false, challengeIndex)}
              />
            </Breadcrumbs>
            <IonList className="hotjar-adjust">
              <IonItem lines="none" key="1000" className="subtitle">
                <IonNote>
                  {t('SolveMyProblemFocusPage_subtitle', {
                    challengeTitleSelected:
                      challengeTitleSelected.toLocaleLowerCase(),
                  })}
                </IonNote>
              </IonItem>
              <IonListHeader>
                <IonLabel>{title}</IonLabel>
              </IonListHeader>
              {focuses?.map((focus, _) => (
                <SolveMyProblemListItem
                  key={focus.index}
                  onClick={() => handleFocusSelection(focus)}
                  button
                >
                  {focus.title}
                </SolveMyProblemListItem>
              ))}
              <SolveMyProblemListItem
                key="1001"
                onClick={handleMissingSolutionClick}
                button
              >
                {t('SolveMyProblemFocusPage_missingSolutionButtonTitle')}
              </SolveMyProblemListItem>
            </IonList>
          </AuthorizedDeckContentAccessContainer>
        </AppContentContainer>
      </IonPage>
    ) : (
      <></>
    )
  );
};

export default SolveMyProblemFocusPage;
