import { TrackUserIdentity } from '../../Analytics/UserInfo/TrackUserIdentity';
import { User } from '../../Authentication/User';

const getSubscribeToUserChangedEventWithIdentityTracking = (
  addAuthStateChangeObserver: (
    onUserChanged: (user: User | null) => void
  ) => () => void,
  onUserChanged: (user: User | null) => void,
  trackUserIdentity: TrackUserIdentity
): (() => () => void) => {
  return () => {
    const onUserChangedWithIdentityTracking = (user: User | null) => {
      // const delay = Math.random() < 0.5 ? 0 : 5000;
      // setTimeout(() => {
      onUserChanged(user);
      trackUserIdentity(user);
      // }, delay);
    };

    return addAuthStateChangeObserver(onUserChangedWithIdentityTracking);
  };
};

export default getSubscribeToUserChangedEventWithIdentityTracking;
