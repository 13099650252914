export const cardCategories = {
  "1": [
    {
      "id": 27,
      "title": "Goals",
      "description": "Agree on a desired end-state to work towards, so daily activity is moving you in the right direction.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 23,
      "title": "Understand",
      "description": "Unpack problems and discover insights with critical and analytical thinking exercises.",
      "iconCode": "&#xE802",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 28,
      "title": "Frame",
      "description": "Articulate your problem or experiment in a clear way, making it easier to start solving or testing.",
      "iconCode": "&#xE805",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 29,
      "title": "Ideas",
      "description": "Encourage people to come up with lots of ideas, then refine and develop the best.",
      "iconCode": "&#xE803",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 24,
      "title": "Evaluate",
      "description": "Assess problems or ideas against certain criteria to understand them better.",
      "iconCode": "&#xE806",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 26,
      "title": "Decide",
      "description": "Prioritise ideas or problems based on your needs in order to decide what to focus on.",
      "iconCode": "&#xE808",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 25,
      "title": "Discuss",
      "description": "Facilitate discussion to reach a shared understanding so you can move towards your goals.",
      "iconCode": "&#xE807",
      "color": "#A96DA7",
      "index": 6
    },
    {
      "id": 30,
      "title": "Technique",
      "description": "Core techniques for planning and facilitating successful workshops.",
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "index": 7
    }
  ],
  "2": [
    {
      "id": 32,
      "title": "Concept",
      "description": "Stories shape how we see ourselves and the world around us. Frame your work as an epic adventure.",
      "iconCode": "&#xE803",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 31,
      "title": "Explore",
      "description": "Stories help us navigate confusing, unknown and changing situations. Make a map for your way ahead.",
      "iconCode": "&#xE908",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 33,
      "title": "Character",
      "description": "Stories connect us to other people. Show us why we can trust you.",
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 37,
      "title": "Function",
      "description": "Stories are more effective than facts or opinions by themselves. Put your stories to work.",
      "iconCode": "&#xE90A",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 36,
      "title": "Structure",
      "description": "Stories carry us along thanks to a few basic patterns. Make your ideas flow in a story-ish way.",
      "iconCode": "&#xE90B",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 35,
      "title": "Style",
      "description": "Stories carry useful information. Make sure people remember yours.",
      "iconCode": "&#xE90C",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 34,
      "title": "Organise",
      "description": "Stories work, so long as you use them well. Plan how you should tell yours.",
      "iconCode": "&#xE90D",
      "color": "#A96DA7",
      "index": 6
    }
  ],
  "3": [
    {
      "id": 72,
      "title": "Situation",
      "description": "Understand the reality of the world you’re dealing with and the people within it, so you can spot opportunities for innovation.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 71,
      "title": "Ideas",
      "description": "Come up with surprising new ideas for products, services or experiences, using exercises that stimulate your creativity.",
      "iconCode": "&#xE803",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 67,
      "title": "Focus",
      "description": "Enable yourself to start with the riskiest aspect of an idea. Then, if you need to adapt, you’ll have the leeway to take more ‘shots’ and find success.",
      "iconCode": "&#xE806",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 68,
      "title": "Signals",
      "description": "Identify which type(s) of signals you’d need to see to feel confident that your innovation will succeed.",
      "iconCode": "&#xE800",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 66,
      "title": "Probe",
      "description": "Poke the world for the signals you need. How have (or haven’t) real humans changed their behaviour as a result of something you put out there?",
      "iconCode": "&#xE911",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 70,
      "title": "Sense",
      "description": "Generate actionable insights from the signals you gathered. Then you can either adapt your plans, or move forward with confidence and momentum.",
      "iconCode": "&#xE802",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 69,
      "title": "Support",
      "description": "Core methods for innovation.",
      "iconCode": "&#xE900",
      "color": "#A96DA7",
      "index": 6
    }
  ],
  "4": [
    {
      "id": 136,
      "title": "Prime",
      "description": "Ready your body and mind to make creative leaps.",
      "iconCode": "&#xE912",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 137,
      "title": "Frame",
      "description": "Describe your challenge and draw the boundaries of your creative playground.",
      "iconCode": "&#xE805",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 138,
      "title": "Explore",
      "description": "See existing insights with fresh, bright, wide-open eyes, and create artefacts to inspire ideas.",
      "iconCode": "&#xE908",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 139,
      "title": "Diverge",
      "description": "Go wide – approach your challenge from different angles and generate lots of potential directions.",
      "iconCode": "&#xE911",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 140,
      "title": "Stretch",
      "description": "Push your ideas further without losing sight of what matters.",
      "iconCode": "&#xE90F",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 141,
      "title": "Refine",
      "description": "Add detail and depth to your strongest ideas.",
      "iconCode": "&#xE910",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 142,
      "title": "Review",
      "description": "Get meaningful feedback, identify the most compelling elements of your ideas, and decide which ideas to pursue.",
      "iconCode": "&#xE90E",
      "color": "#A96DA7",
      "index": 6
    },
    {
      "id": 143,
      "title": "Cheatsheet",
      "description": "Get more out of your creative process with mindful planning, preparation and facilitation.",
      "iconCode": "&#xE800",
      "color": "#23232F",
      "index": 7
    }
  ],
  "5": [
    {
      "id": 129,
      "title": "Purpose",
      "description": "Find out why you should even bother, then decide what you want to accomplish.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 130,
      "title": "Identify",
      "description": "Make any situation discussable by breaking it down into clear whos and whats.",
      "iconCode": "&#xE806",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 131,
      "title": "Connect",
      "description": "Find out how all the pieces fit together to learn how the whole thing works.",
      "iconCode": "&#xE90D",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 132,
      "title": "Evolve",
      "description": "Embrace market movement, so you can go with the flow – instead of crashing into it.",
      "iconCode": "&#xE913",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 133,
      "title": "Adapt",
      "description": "Change your behavior so you can thrive within a challenging, competitive environment.",
      "iconCode": "&#xE912",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 134,
      "title": "Plays",
      "description": "Change the environment itself until it meets your needs.",
      "iconCode": "&#xE911",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 135,
      "title": "Lead",
      "description": "Be clear, do the right thing and help others contribute their best.",
      "iconCode": "&#xE908",
      "color": "#A96DA7",
      "index": 6
    }
  ],
  "6": [
    {
      "id": 144,
      "title": "Environment",
      "description": "Get into the teamwork mindset so you are all setting off together.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 145,
      "title": "Direction",
      "description": "Decide on a clear vision and work out the values and principles that will get you there, together.",
      "iconCode": "&#xE905",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 146,
      "title": "Support",
      "description": "Make sure your team doesn’t get lost along the way by giving them the support they need to succeed.",
      "iconCode": "&#xE900",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 147,
      "title": "Health",
      "description": "Monitor how your team is doing so you can see clearly when your help is needed the most.",
      "iconCode": "&#xE903",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 148,
      "title": "Collaborate",
      "description": "Encourage team members to work together to help them design better products and services.",
      "iconCode": "&#xE902",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 149,
      "title": "Communicate",
      "description": "Encourage people to talk about their work, sharing it early and with clarity to foster trust.",
      "iconCode": "&#xE807",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 150,
      "title": "Recognition",
      "description": "Foster a culture of appreciation by rewarding and recognising your team’s efforts.",
      "iconCode": "&#xE901",
      "color": "#A96DA7",
      "index": 6
    },
    {
      "id": 151,
      "title": "Technique",
      "description": "Core tactics to get organised, make decisions, ask good questions and set metrics.",
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "index": 7
    }
  ],
  "7": [
    {
      "id": 152,
      "title": "Context",
      "description": "All brands are understood in relation to the world around them. Find the gaps and opportunities you could exploit.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 153,
      "title": "Introspection",
      "description": "Every business has hidden advantages and disadvantages. Discover yours so you know what you’re working with.",
      "iconCode": "&#xE913",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 154,
      "title": "Strategy",
      "description": "Great brands offer something to the world nobody else does. Figure out what that thing is for you.",
      "iconCode": "&#xE90D",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 155,
      "title": "Judgement",
      "description": "Insights are great, but you’ve got to make them usable and effective. Transform those raw ideas into clear strategic direction.",
      "iconCode": "&#xE806",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 156,
      "title": "Execution",
      "description": "Creative execution is so much more than a logo and some ads. Bring your strategy to life across the whole of your business.",
      "iconCode": "&#xE90C",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 157,
      "title": "Double Down",
      "description": "Iconic brands keep things fresh by pushing deeper and further every year. Explore just how far you can take it.",
      "iconCode": "&#xE901",
      "color": "#E4ABC9",
      "index": 5
    }
  ],
  "8": [
    {
      "id": 158,
      "title": "Prepare",
      "description": "Get yourself in the right headspace to nail your goals, every time.",
      "iconCode": "&#xE912",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 159,
      "title": "Plan",
      "description": "Streamline your workflow and keep track of everything you’re working on.",
      "iconCode": "&#xE90D",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 160,
      "title": "Prioritise",
      "description": "Spend your precious energy wisely with these tactics for ruthless prioritisation.",
      "iconCode": "&#xE808",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 161,
      "title": "Do",
      "description": "The time for talk is over; get in the zone, obliterate distractions and power through your to-do list.",
      "iconCode": "&#xE908",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 162,
      "title": "Reflect",
      "description": "Avoid getting stuck in a rut by regularly reflecting on and refining your processes.",
      "iconCode": "&#xE802",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 163,
      "title": "Reduce",
      "description": "Even at maximum productivity, there’s only so much you can do. Prune the dead wood from your schedule and watch your impact double.",
      "iconCode": "&#xE800",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 164,
      "title": "Reset",
      "description": "If hard work just ain’t working, take things back to basics with a ‘reset’ ritual.",
      "iconCode": "&#xE913",
      "color": "#A96DA7",
      "index": 6
    }
  ],
  "9": [
    {
      "id": 166,
      "title": "Start",
      "description": null,
      "iconCode": "&#xe807",
      "color": "#A96DA7",
      "index": 1
    },
    {
      "id": 165,
      "title": "Discuss",
      "description": null,
      "iconCode": "&#xE807",
      "color": "#A96DA7",
      "index": 2
    },
    {
      "id": 170,
      "title": "Decide",
      "description": null,
      "iconCode": "&#xE808",
      "color": "#E4ABC9",
      "index": 3
    },
    {
      "id": 171,
      "title": "Technique",
      "description": null,
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "index": 4
    },
    {
      "id": 172,
      "title": "Frame",
      "description": null,
      "iconCode": "&#xE805",
      "color": "#F5C466",
      "index": 5
    }
  ],
  "10": [
    {
      "id": 167,
      "title": "Start",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "index": 1
    },
    {
      "id": 168,
      "title": "Recipes",
      "description": null,
      "iconCode": "&#xe907",
      "color": "#000",
      "index": 2
    },
    {
      "id": 169,
      "title": "Archetypes",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "index": 3
    },
    {
      "id": 173,
      "title": "Character",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "index": 3
    },
    {
      "id": 174,
      "title": "Concept",
      "description": null,
      "iconCode": "&#xE803",
      "color": "#D16461",
      "index": 5
    },
    {
      "id": 175,
      "title": "Structure",
      "description": null,
      "iconCode": "&#xE90B",
      "color": "#87A6CD",
      "index": 6
    },
    {
      "id": 176,
      "title": "Style",
      "description": null,
      "iconCode": "&#xE90C",
      "color": "#E4ABC9",
      "index": 7
    }
  ],
  "11": [
    {
      "id": 177,
      "title": "Principles",
      "description": "Learn the timeless principles of ethical and effective negotiation.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "index": 0
    },
    {
      "id": 178,
      "title": "Active Listening",
      "description": "Practise listening skills that make you likeable and help you better understand your counterpart.",
      "iconCode": "&#xE909",
      "color": "#EB986C",
      "index": 1
    },
    {
      "id": 179,
      "title": "Research",
      "description": "Dig deep to discover all kinds of information that you can use to your advantage.",
      "iconCode": "&#xE802",
      "color": "#F5C466",
      "index": 2
    },
    {
      "id": 180,
      "title": "Plan",
      "description": "Create a solid plan and put all the information you gathered to good use.",
      "iconCode": "&#xE902",
      "color": "#9CC992",
      "index": 3
    },
    {
      "id": 181,
      "title": "Build A Relationship",
      "description": "Start building trust and developing the foundations of a mutually beneficial relationship.",
      "iconCode": "&#xE903",
      "color": "#87A6CD",
      "index": 4
    },
    {
      "id": 182,
      "title": "Negotiate",
      "description": "Move from simply communicating to actively influencing the situation in your favour.",
      "iconCode": "&#xE807",
      "color": "#E4ABC9",
      "index": 5
    },
    {
      "id": 183,
      "title": "Troubleshoot",
      "description": "Identify and overcome common barriers to negotiation – and know when to walk away.",
      "iconCode": "&#xE900",
      "color": "#A96DA7",
      "index": 6
    }
  ]
};